const bookingPaths = {
  BOOKING: "",
  SEARCH_BOOKING: "search/v2",
  BOOKING_ID: (bookingId) => bookingId,
  BOOKING_SUMMARY: (bookingId) => `${bookingId}/summary`,
  SEARCH_BOOKING_GUESTS: (bookingId) => `${bookingId}/guest`,
  SEARCH_BOOKING_ACCOMMODATION: (bookingId) => `${bookingId}/accommodation`,
  BOOKING_SOURCES: "get-all-sources",
  VALIDATE_BOOKING_FILE: "import-file/validate",
  IMPORT_FILE: "import-file",
};

export default bookingPaths;
