import { Button, Modal } from "components";
import React from "react";
import lang from "translations";
import { StyleType } from "enums";

const ImportInfoModal = (props) => {
  const { onNo, onYes, text } = props;

  return (
    <Modal
      {...props}
      customTitle={
        <p className="text-xl text-pelorous-darker font-semibold pt-3">{lang.warning}</p>
      }
      >
      <div>
        <div className="my-5">{text}</div>
      </div>
      <div className="flex gap-2 justify-center py-8">

        <Button  type={StyleType.Primary} onClick={onYes}>{lang.yes}</Button>
        <Button   type={StyleType.Danger} onClick={onNo}>{lang.no}</Button>
      </div>
    </Modal>
  );
};

export default ImportInfoModal;
