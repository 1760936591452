import { searchBookings, validateBookingFile, importBookingFile } from "apis/booking.api";
import classNames from "classnames";
import { ButtonLink, DataTable, Text, Tooltip, Icon } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderA } from "components/headers";
import { VenueContext } from "contexts";
import { BookingStatus, DateTime, IntegrationType } from "enums";
import { useApi, useFilter, useMount, useModal } from "hooks";
import { bookingListFilterRequest, bookingListResponse } from "mappers/booking.mapper";
import { mixpanel } from "mixpanel";
import { Path } from "paths";
import React, { useCallback, useContext, useMemo, useState, useEffect, useRef } from "react";
import { formatDate, mapObject, prettifyIntegrationType, normalizeBookingStatus } from "services";
import lang from "translations";
import BookingStatusPill from "../booking-status-pill";
import BookingFilter from "./booking-filter";
import BookingSummary from "./booking-summary.module";
import columns from "./columns";
import { bookingListFilterState } from "./filters";
import { useFlags } from "launchdarkly-react-client-sdk";
import useFilterStore, { filterName } from "hooks/filterStore";
import { isEmpty } from "lodash";
import { environment } from "environments/environment";
import ImportErrorModal from "./import-error";
import ImportTypeModal from "./import-type";
import ImportInfoModal from "./import-info";
import LoaderB from "components/commons/loaderB/loaderB";
import { uploadFileV2 } from "apis/storage.api";
import { MB } from "contanst";
import { EMode,BookingDataImportType } from "enums";

const BookingList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const defaultMode = venue?.defaultMode || "";
  const { bookingListImprovement } = useFlags();
  const { setState: setFilterState, getState } = useFilterStore();
  const [importType, setImportType] = useState(undefined);
  const importTypeRef = useRef(); // Create a ref to store the current value of importType
  const importTypeModel = useModal();
  const importErrModal = useModal();
  const importInfoModal = useModal();
  const [fileLoading, setLoading] = useState(false);
  const {
    request: searchBookingsRequest,
    error,
    mappedData,
    result: searchBookingsResult = { data: [], metadata: { total: 0 } },
    loading,
  } = useApi({
    api: searchBookings,
    isArray: true,
    handleOwnError: true,
    mapper: bookingListResponse,
  });

  const { request: validateBookingFileRequest } = useApi({
    api: validateBookingFile,
    params: {
      venueId,
      path: "uploads",
    },
  });

  const { request: importBookingFileRequest} = useApi({
    api: importBookingFile,
    params: {
      venueId,
      path: "uploads",
    },
  });

  const { modifyFilter, clearFilter, filterState, requestState, modifyFilters, isFilterDirty } =
    useFilter(
      filterName.booking === getState().name && !isEmpty(getState().filter)
        ? getState().filter
        : bookingListFilterState(venue),
      bookingListFilterState(venue)
    );

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    if (filterName.booking !== getState().name)
      setFilterState({
        name: filterName.booking,
        filter: {},
      });

    fetchBookings(requestState);
  });

  const fetchBookings = useCallback(
    async (requestState) => {
      await searchBookingsRequest(mapObject(requestState, bookingListFilterRequest));
    },
    [searchBookingsRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterName.booking,
        filter: filterState,
      });
      fetchBookings(requestState);
    },
    [fetchBookings, setFilterState]
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilters({
        page: 1,
        searchKey,
      });
      await fetchUpdateStore(requestState, filterState);
      setHasAppliedFilter(true);
    },
    [fetchUpdateStore, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState, filterState } = clearFilter();
    fetchUpdateStore(requestState, filterState);
    setHasAppliedFilter(false);
  }, [clearFilter, fetchUpdateStore]);

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.bookingList,
    });
  });

  const prepareProductList = useCallback(
    (mappedData) => {
      return mappedData.map((bookingItem) => {
        const {
          id,
          bookingNumber,
          startTime,
          endTime,
          bookedBy,
          noOfNights,
          noOfGuests,
          emailAddress,
          source = "",
          status,
          rawStatus,
          noOfCheckedIn = 0,
          noOfCheckedOut = 0,
        } = bookingItem;

        return {
          id: (
            <>
              <ButtonLink
                className="cursor-pointer whitespace-nowrap text-blue"
                path={Path.BOOKING_ID(id)}
              >
                {bookingNumber}
              </ButtonLink>
              {bookingListImprovement && (
                <Text color="text-gray">{prettifyIntegrationType(source)}</Text>
              )}
            </>
          ),
          startTime: (
            <>
              <Text className="whitespace-nowrap">{formatDate(startTime, DateTime.C)}</Text>
              {source !== IntegrationType.CLOUDBEDS && (
                <Text color="text-gray">{formatDate(startTime, DateTime.B)}</Text>
              )}
            </>
          ),
          endTime: (
            <>
              <Text className="whitespace-nowrap">{formatDate(endTime, DateTime.C)}</Text>
              {source !== IntegrationType.CLOUDBEDS && (
                <Text color="text-gray">{formatDate(endTime, DateTime.B)}</Text>
              )}
            </>
          ),
          bookedBy: (
            <>
              <Text>{bookedBy}</Text>
              <Text color="text-gray">{emailAddress}</Text>
            </>
          ),
          noOfNights: <Text>{noOfNights}</Text>,
          noOfGuests: <Text>{noOfGuests}</Text>,
          bookingStatus: bookingListImprovement ? (
            <BookingStatusPill
              status={
                source !== IntegrationType.CLOUDBEDS
                  ? normalizeBookingStatus(bookingItem)
                  : rawStatus
              }
              source={source}
            ></BookingStatusPill>
          ) : (
            <BookingStatusPill status={normalizeBookingStatus(bookingItem)}></BookingStatusPill>
          ),
          guestsStatus: bookingListImprovement && (
            <div className="flex flex-col text-sm">
              <lang.Translate
                className={classNames("text-sm", {
                  "text-green": status === BookingStatus.CHECKED_IN || BookingStatus.IN_HOUSE,
                  "text-red":
                    status === BookingStatus.CHECKED_OUT ||
                    BookingStatus.MISSED_CHECKED_OUT ||
                    BookingStatus.NO_SHOW ||
                    BookingStatus.CANCELLED,
                })}
                text={lang.noOfCheckedIn}
                items={[<span className="font-semibold text-sm">{noOfCheckedIn}</span>]}
              />
              <lang.Translate
                className="text-sm text-gray"
                text={lang.noOfCheckedOut}
                items={[<span className="font-semibold text-sm">{noOfCheckedOut}</span>]}
              />
              <Text></Text>
            </div>
          ),
        };
      });
    },
    [bookingListImprovement]
  );

  const bookings = useMemo(() => {
    return prepareProductList(mappedData);
  }, [mappedData, prepareProductList]);


  const options = [lang.importFile, lang.downloadTemplate];

  const handleExportClick = (selectedIndex) => {
    if (selectedIndex === 0) {
      importTypeModel.show();
    } else if (selectedIndex === 1) {
      downloadFile();
    }
  };

  const downloadFile = () => {
    const fileUrl = environment.BOOKING_DATA_TEMPLATE_URL;
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'import-tickets-template.xlsx';
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const onUploadFile = useCallback(
    async (e) => {
      try {
        const file = e?.target?.files[0];
        if (file.size > 25 * MB) {
          importErrModal.show({ text: lang.populate(lang.fileSizeExceeded, ['25 MB']) });
          return;
        }
        if (!checkFile(file)) {
          importErrModal.show({ text: lang.populate(lang.invalidBookingFileType, ['.xlsx', '.xlsx']) });
          return;
        }
        setLoading(true);
        const res = await uploadFileV2({ file });        
        if (res[0].successful) {
          const newFileName = res[0].newFilename;
          const originalFilename = res[0].originalFilename;
          const params = { newFileName, fileName: originalFilename, importType: importTypeRef.current };
          const validateBookingFileResponse = await validateBookingFileRequest({ ...params, venueId });
          if(validateBookingFileResponse.status === "success"){
            if(params.importType === BookingDataImportType.New ){
              importInfoModal.show({ 
                text: lang.populate(lang.newBookingDataImport, [validateBookingFileResponse?.data?.existedBooking.length, validateBookingFileResponse?.data?.acceptedTicketsNumber]),
                onNo: () => {
                  importInfoModal.close();
                  return;
                },
                onYes: async () => {
                  importInfoModal.close();
                  await importBookingFileRequest({ ...params, venueId });
                },
              });
              
            }
            
          }
          
        }
      } catch (error) {
        let text = lang.undefinedErrorPlsTryAgain;
        importErrModal.show({ text });
      } finally {
        setLoading(false);
      }
    },
    [importErrModal,importBookingFileRequest, validateBookingFileRequest, setLoading, venueId,importInfoModal]
  );


  useEffect(() => {
    importTypeRef.current = importType;
  }, [importType]);

  const onUpload = () => {
    const a = document.createElement("input");
    a.type = "file";
    a.accept = ".xlsx";
    a.style.display = "none";
    a.onchange = onUploadFile;
    a.click();
    a.remove();
  };

  const checkFile = (file) => {
    var validExts = [".xlsx"];
    var fileExt = file.name;
    fileExt = fileExt.substring(fileExt.lastIndexOf("."));
    if (validExts.indexOf(fileExt) < 0) {
      return false;
    }
    return true;
  };


  const handleModalCloseAndUpload = () => {
    importTypeModel.close();  // Close modal on user action
    onUpload();  
  };

  return (
    <ModuleWrapper
      header={<HeaderA title={lang.bookingList}
        description={lang.bookingDescription}
        button={{
          path: Path.STAFF_CREATE,
          text: lang.export,
        }}

        buttonsGroup={defaultMode === EMode.Event ? {
          label: "Import",
          options: options,
          handleClick: (selectedIndex) => {
            handleExportClick(selectedIndex);
          },
        } : null}


      />}
    >
      <>
        <BookingFilter
          filterState={filterState}
          requestState={requestState}
          modifyFilter={modifyFilter}
          modifyFilters={modifyFilters}
          applyFilter={applyFilterCb}
          clearFilter={clearFilterCb}
        />
        <BookingSummary
          totalBookings={searchBookingsResult?.metadata?.count}
          toBeCheckedInCount={searchBookingsResult?.metadata?.toBeCheckedIn}
          toBeCheckedOutCount={searchBookingsResult?.metadata?.toBeCheckedOut}
        />
        <text>{searchBookingsResult?.metadata?.count} {lang.searchResults}</text>
        <DataTable
          error={error}
          loading={loading}
          total={searchBookingsResult?.metadata?.count || 0}
          columns={[
            ...columns,
            {
              key: "bookingStatus",
              text: (
                <div className="flex items-center gap-2">
                  <Text fontWeight="font-semibold" color="text-washed-dark">
                    {bookingListImprovement ? lang.bookingStatus : lang.status}
                  </Text>

                  {bookingListImprovement && (
                    <Tooltip
                      title={
                        <Text className="text-center" color="text-white">
                          {lang.theBookingStatus}
                        </Text>
                      }
                      placement="top"
                    >
                      <span>
                        <Icon name="info" fontSize="14px" color="text-gray-light"></Icon>
                      </span>
                    </Tooltip>
                  )}
                </div>
              ),
            },
            bookingListImprovement && {
              key: "guestsStatus",
              text: (
                <div className="flex items-center gap-2">
                  <Text fontWeight="font-semibold" color="text-washed-dark">
                    {lang.guestsStatus}
                  </Text>
                  <Tooltip
                    title={
                      <Text className="text-center" color="text-white">
                        {lang.theActualNumberOfGuests}
                      </Text>
                    }
                    arrowPointAtCenter
                    placement="topRight"
                  >
                    <span>
                      <Icon name="info" fontSize="14px" color="text-gray-light" paddingless></Icon>
                    </span>
                  </Tooltip>
                </div>
              ),
            },
          ]}
          hasAppliedFilter={hasAppliedFilter}
          customNoResultError={lang.noResults}
          customNoResultErrorDesc={lang.cantFindDesc}
          renderEmpty={{ title: lang.noBookingsYet, description: lang.youllFindYourBookingsHere }}
          data={bookings}
          onChangePage={modifyFilters}
          fetchList={fetchUpdateStore}
          page={filterState.page}
          pageSize={filterState.pageSize}
          minWidth="800px"
        />
        <ImportErrorModal {...importErrModal} />
        <ImportInfoModal {...importInfoModal} />
        
        <LoaderB loading={fileLoading} />
        <ImportTypeModal
        {...importTypeModel}
        onSelect={(importType) => {
          setImportType(importType);
          handleModalCloseAndUpload();
        }}
      />
      </>

    </ModuleWrapper>
  );
};

export default BookingList;
