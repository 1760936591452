import { Button, Modal } from "components";
import React from "react";
import lang from "translations";

const ImportErrorModal = (props) => {
  const { onClose, text } = props;

  return (
    <Modal
      {...props}
      customTitle={
        <p className="text-xl text-pelorous-darker font-semibold pt-3">{lang.importFail}</p>
      }
      >
      <div>
        <div className="my-5">{text}</div>
      </div>
      <div className="flex gap-2 justify-center py-8">

        <Button onClick={onClose}>{lang.ok}</Button>
      </div>
    </Modal>
  );
};

export default ImportErrorModal;
