import { ActionButton, Modal } from "components/commons";
import { StyleType, BookingDataImportType } from "enums";
import React from "react";
import lang from "translations";

const ImportTypeModal = (props) => {
  const { onSelect } = props;

  return (
    <Modal
      {...props}
      customTitle={
        <p className="text-xl text-pelorous-darker font-semibold pt-3">{lang.selectImportType}</p>
      }
      actionContent={

        <div
            className={"flex justify-center"
            }
          >
        <ActionButton
          className="px-md pb-md"
          secondary={{
            text: lang.incremental,
            onClick: () => {
              onSelect(BookingDataImportType.Incremental);
            },
          }}
          primary={{
            text: lang.new,
            type: StyleType.Primary,
            onClick: () => {
              onSelect(BookingDataImportType.New);
            },
          }}
        />
        </div>
      }
      >
      <div>
        <div className="my-5">{lang.selectImportTypeDesc}</div>
      </div>
     
    </Modal>
  );
};

export default ImportTypeModal;
